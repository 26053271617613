import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { CheckIcon } from 'icons/internal/CheckIcon';
import { HeartIcon } from 'icons/internal/HeartIcon';
import { Flex } from 'components/Flex';
import { Rhythm } from 'components/Rhythm';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { IconCount } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "iconcount"
    }}>{`IconCount`}</h1>
    <h2 {...{
      "id": "basic-iconcount"
    }}>{`Basic IconCount`}</h2>
    <Playground __position={0} __code={'<ThemeWrapper>\n  <IconCount count={24} icon={HeartIcon} label=\"Example\" />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HeartIcon,
      Flex,
      Rhythm,
      ThemeWrapper,
      IconCount,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconCount count={24} icon={HeartIcon} label="Example" mdxType="IconCount" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "active-iconcount"
    }}>{`Active IconCount`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <IconCount active count={24} icon={HeartIcon} label=\"Example\" />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HeartIcon,
      Flex,
      Rhythm,
      ThemeWrapper,
      IconCount,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconCount active count={24} icon={HeartIcon} label="Example" mdxType="IconCount" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "reversed-iconcount"
    }}>{`Reversed IconCount`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <IconCount\n    active\n    inline\n    reverse\n    count={24}\n    icon={CheckIcon}\n    label=\"Example\"\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HeartIcon,
      Flex,
      Rhythm,
      ThemeWrapper,
      IconCount,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconCount active inline reverse count={24} icon={CheckIcon} label="Example" mdxType="IconCount" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "iconcount-with-custom-sizes"
    }}>{`IconCount with custom sizes`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Flex wrap direction=\"row\" justifyContent=\"space-between\">\n    <Rhythm m={2}>\n      <IconCount\n        count={24}\n        icon={HeartIcon}\n        iconSize={16}\n        label=\"Example\"\n        typographySize=\"small\"\n      />\n      <IconCount\n        count={24}\n        icon={HeartIcon}\n        iconSize={18}\n        label=\"Example\"\n        typographySize=\"medium\"\n      />\n      <IconCount\n        count={24}\n        icon={HeartIcon}\n        iconSize={20}\n        label=\"Example\"\n        typographySize=\"large\"\n      />\n      <IconCount\n        count={24}\n        icon={HeartIcon}\n        iconSize={24}\n        label=\"Example\"\n        typographySize=\"xlarge\"\n      />\n    </Rhythm>\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CheckIcon,
      HeartIcon,
      Flex,
      Rhythm,
      ThemeWrapper,
      IconCount,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex wrap direction="row" justifyContent="space-between" mdxType="Flex">
      <Rhythm m={2} mdxType="Rhythm">
        <IconCount count={24} icon={HeartIcon} iconSize={16} label="Example" typographySize="small" mdxType="IconCount" />
        <IconCount count={24} icon={HeartIcon} iconSize={18} label="Example" typographySize="medium" mdxType="IconCount" />
        <IconCount count={24} icon={HeartIcon} iconSize={20} label="Example" typographySize="large" mdxType="IconCount" />
        <IconCount count={24} icon={HeartIcon} iconSize={24} label="Example" typographySize="xlarge" mdxType="IconCount" />
      </Rhythm>
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={IconCount} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      